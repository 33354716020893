.MuiAppBar-root {
    background-color: brown !important;
    background: linear-gradient(
        90deg,
        rgba(165, 42, 42, 1) 0%,
        rgba(195, 48, 48, 1) 35%,
        rgba(182, 91, 48, 1) 70%,
        rgba(165, 42, 42, 1) 100%
    );
}

.MuiBox-root {
    flex-grow: 0 !important;
}

aside:not(.ps-toggled) {
    margin-left: -1px;
}

.side-menu-button-label {
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 50px;
    white-space: break-spaces !important;
    color: black;
    padding: 8px 20px 8px 40px;
}

.side-menu-button-label:hover {
    background-color: brown;
    color: white;
    transition: 0.5s;
}

.app-content {
    flex: 1;
    overflow-y: auto;
}

.App {
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.bg-img-holder {
    position: fixed;
    z-index: -1000;
    width: 100vw;
    height: 100vh;
}

.bg-img-holder > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.6;
}

h1 {
    text-align: center;
    margin-top: 0;
}

.book-page {
    margin: 2rem 1rem;
    padding: 2rem;
    max-width: calc(100vw - 1rem);
    width: 800px;
    background-color: white;
    -webkit-box-shadow: 12px 11px 57px -11px rgba(0, 0, 0, 0.71);
    -moz-box-shadow: 12px 11px 57px -11px rgba(0, 0, 0, 0.71);
    box-shadow: 12px 11px 57px -11px rgba(0, 0, 0, 0.71);
    border-radius: 4px;
}

.main-content-container {
    display: flex;
    justify-content: center;
}

.divider {
    position: relative;
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;
    height: 1px;
}

.div-transparent:before {
    content: '';
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;
    background-image: linear-gradient(
        to right,
        transparent,
        rgb(48, 49, 51),
        transparent
    );
}

.div-dot:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: -9px;
    left: calc(50% - 9px);
    width: 18px;
    height: 18px;
    background-color: brown;
    border: 1px solid rgb(48, 49, 51);
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px white, 0 0 0 4px white;
}

.search-results-container {
    position: absolute;
    right: 0;
    color: black;
    width: 400px;
    max-width: 90vw;
    margin-top: 4px;
    z-index: 1000;
}

.search-results-container.hidden {
    opacity: 0;
    visibility: hidden;
}

.search-results {
    padding: 1rem;
}

.search-result-link > a {
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: black;
    display: flex;
    border-bottom: 1px solid gray;
    padding: 0.75rem;
    column-gap: 1rem;
}

.search-result-link > a:hover {
    background-color: darkmagenta;
    color: white;
    transition: 0.5s;
}
