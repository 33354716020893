.article-link {
    margin: 1rem 0;
}

.article-link > a {
    font-size: 1.25rem;
    text-decoration: none;
    color: black;
    display: flex;
    border: 1px solid brown;
    padding: 0.75rem;
    column-gap: 2rem;
}

.article-link > a:hover {
    color: white;
    background-color: brown;
    transition: 0.5s;
}
