@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT&family=Quicksand:wght@400&display=swap');

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif; */
    font-family: 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
